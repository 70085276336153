<template>
  <div class="about-section section-space--inner--100">
    <div class="container">
      <div class="row row-25 align-items-center">
        <div class="col-lg-6 col-12 mb-30">
          <div class="about-image-two">
            <img :src="$d.imgUrl" alt="image">
          </div>
        </div>
        <div class="col-lg-6 col-12 mb-30">
          <div class="about-content-two">
            <!-- <h3>{{ data.aboutUs.subTitle }}</h3> -->
            <h1>{{ $d.name }}</h1>
            <h4>{{ $d.slogan }}</h4>
            <p>{{ $d.desc }}</p>
            <router-link to="service" class="ht-btn--default ht-btn--default--dark-hover section-space--top--20">业务范围</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>
